import { useEffect, useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { Button, Modal } from "semantic-ui-react";
// @ts-ignore
import VideoPlayer from "components/youtubevideo";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, ui } from "services";
import { TelegramConnect } from "./celest";
import { MongenRace } from "interfaces";

export const MysticPlanet = ({ claimed_quest, updateQuest, claimStar }: any) => {
    const [popup, setPopup] = useState(0);
    const [isOpenVideo, openVideo] = useState(false);
    const [timeView, setTimeView] = useState(0);
    const [isPlay, setIsPlay] = useState(false);
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.user);
    const TIME_REQUIRE = 15;
    const [telegramId, setTelegramId] = useState(sessionStorage.getItem('telegramId'))
    const [telegramStep, setTelegramStep] = useState(1);
    const [loading, setLoading] = useState(false);

    const increaseTask = async (task_id: number, telegramId?: string) => {
        setLoading(true)
        try {
            let { customerQuest, rewards } = await api.postEventDeal('/birthday/increase-mystic-task', {
                task_id,
                telegramId: telegramId ? telegramId.toString() : null
            })
            updateQuest && updateQuest(customerQuest)
            setPopup(0);
            ui.openRewardPopup(rewards);
        } catch (err: any) {
            ui.alertFailed(t(err?.message), "")
            console.log({ err })
            setPopup(0);
        } finally {
            setLoading(false)
        }
    }

    const onPlay = () => {
        console.log("Play")
        setIsPlay(true);
    };

    const onPause = () => {
        console.log("Pause")
        setIsPlay(false);
    };

    const onEnd = () => {
        console.log("End")
    };

    const POPUP_UI = [
        <div className="w-full h-full">
            <div className="h-3/5 w-full relative">
                <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    Submit a Fanart
                </div>
                <div className="w-11/12 mx-auto flex justify-center h-full">
                    <div className="text-center text-white text-2xl font-cookie-run font-bold self-center w-full">
                        You need to submit a fanart to this form:
                        <div className="break-words text-lg mt-2">
                            https://docs.google.com/forms/d/e/1FAIpQLSddtNtxog9RQ_9gaHLIJR76MJSdD_F3XP963sVoXa3zaxF_iQ/viewform
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
                <div className="flex justify-center mx-auto text-center w-full gap-8">
                    <Button
                        color="blue"
                        style={{
                            background: '#b69cf1',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#b69cf1]"
                        onClick={async () => {
                            window.open('https://docs.google.com/forms/d/e/1FAIpQLSdk9BclqpkKG0G4MXM1ptqSbmjUAlRCEwZsDJHeVJ-ZkDkZvg/viewform', '_blank')
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">Open form</div>
                    </Button>
                    {!claimed_quest?.includes(1) && <Button
                        color="blue"
                        style={{
                            background: '#62FFFF',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#62FFFF]"
                        loading={loading}
                        onClick={async () => {
                            if (!claimed_quest?.includes(1)) {
                                await increaseTask(1);
                            }
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">Verify</div>
                    </Button>}
                </div>
            </div>
        </div>,

        <div className="w-full h-full">
        </div>,

        <div className="w-full h-full">
            <div className="h-3/5 w-full relative">
                <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    Join Telegram channel
                </div>
                <div className="w-full mx-auto flex justify-center h-full">
                    <div className="text-center text-white text-2xl font-cookie-run font-bold self-center w-full">
                        {telegramStep === 1 && <span>STEP 1/3: Join group @monsterra_official <br /> to complete this task.</span>}
                        {telegramStep === 2 && <span>STEP 2/3: Start @monsterrabirthday_bot <br />to verify this task</span>}
                        {telegramStep === 3 && <span>STEP 3/3: Verify this task to claim your rewards</span>}
                    </div>
                </div>
            </div>
            <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
                <div className="flex justify-center mx-auto text-center w-full gap-8">
                    {(telegramStep === 1 || !telegramId) && <Button
                        color="blue"
                        style={{
                            background: '#62FFFF',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#62FFFF]"
                        onClick={async () => {
                            if (!telegramId) {
                                TelegramConnect((user: any) => {
                                    console.log({ user })
                                    if (user?.id) {
                                        sessionStorage.setItem('telegramId', user.id)
                                        setTelegramId(user.id)
                                        window.open('https://t.me/monsterra_official', '_blank')
                                        setTelegramStep(2)
                                    }
                                })
                            } else {
                                window.open('https://t.me/monsterra_official', '_blank')
                                setTelegramStep(2)
                            }
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">Open Telegram</div>
                    </Button>}
                    {telegramStep === 2 && <Button
                        color="blue"
                        style={{
                            background: '#62FFFF',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#62FFFF]"
                        onClick={async () => {
                            window.open(`https://t.me/monsterrabirthday_bot`, "_blank");
                            setTelegramStep(3)
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">
                            Open Telegram
                        </div>
                    </Button>
                    }
                    {telegramStep === 3 && <Button
                        color="blue"
                        style={{
                            background: '#62FFFF',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#62FFFF]"
                        loading={loading}
                        onClick={async () => {
                            if (!claimed_quest?.includes(3)) {
                                await increaseTask(3, telegramId);
                            }
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">
                            Verify
                        </div>
                    </Button>
                    }
                </div>
            </div>
        </div>,

        <div className="w-full h-full">
            <div className="h-3/5 w-full relative">
                <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    Follow Twtter
                </div>
                <div className="w-11/12 mx-auto flex justify-center h-full">
                    <div className="text-center text-white text-2xl font-cookie-run font-bold self-center w-full">
                        You need to follow @Monsterra_P2E on Twitter to complete this task.
                    </div>
                </div>
            </div>
            <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
                <div className="flex justify-center mx-auto text-center w-full gap-8">
                    <Button
                        color="blue"
                        style={{
                            background: '#b69cf1',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#b69cf1]"
                        onClick={async () => {
                            window.open('https://twitter.com/Monsterra_P2E', '_blank')
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">Open Twitter</div>
                    </Button>
                    <Button
                        color="blue"
                        style={{
                            background: '#62FFFF',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        disabled={claimed_quest?.includes(4)}
                        className="w-[84px] bg-[#62FFFF]"
                        loading={loading}
                        onClick={async () => {
                            if (!claimed_quest?.includes(4)) {
                                await increaseTask(4);
                            }
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">
                            Verify
                        </div>
                    </Button>
                </div>
            </div>
        </div>,

        <div className="w-full h-full">
            <div className="h-3/5 w-full relative">
                <div className="text-center text-white text-xl py-6 font-extrabold main-font-bold">
                    Refer 5 friends
                </div>
                <div className="w-11/12 mx-auto flex justify-center h-full">
                    <div className="text-center text-white text-2xl font-cookie-run font-bold self-center w-full">
                        You need to invite 5 friends to play game to complete this task
                    </div>
                </div>
            </div>
            <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
                <div className="flex justify-center mx-auto text-center w-full gap-8">
                    <Button
                        color="blue"
                        style={{
                            background: '#b69cf1',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#b69cf1]"
                        onClick={async () => {
                            window.open('https://dao.monsterra.io', '_blank')
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">Open DAO</div>
                    </Button>
                    <Button
                        color="blue"
                        style={{
                            background: '#62FFFF',
                            width: '180px',
                            color: 'black',
                            borderRadius: '30px'
                        }}
                        className="w-[84px] bg-[#62FFFF]"
                        loading={loading}
                        onClick={async () => {
                            if (!claimed_quest?.includes(5)) {
                                await increaseTask(5);
                            }
                        }}
                    >
                        <div className="text-xl font-bold font-cookie-run">Verify</div>
                    </Button>
                </div>
            </div>
        </div>,
    ]

    useEffect(() => {
        let interval: any;
        if (isPlay) {
            interval = setInterval(() => {
                if (timeView < TIME_REQUIRE) {
                    setTimeView(timeView + 1)
                    console.log(timeView + 1)
                }
            }, 1000)
        }

        return (() => {
            clearInterval(interval)
        })
    }, [isPlay, timeView])

    useEffect(() => {
        if (timeView >= TIME_REQUIRE && !claimed_quest?.includes(2) && user?.id) {
            increaseTask(2)
        }
    }, [timeView, claimed_quest, user?.id])

    return (
        <div className="w-full h-full min-w-screen min-h-screen pt-8 flex justify-center bg-banner3 bg-contain transform-origin" id='mystic-planet'>
            <Modal
                key="emailForm"
                open={isOpenVideo}
                style={{ width: "unset", background: 'transparent' }}
                closeIcon={
                    <>
                        {timeView >= TIME_REQUIRE ? <div className="text-red w-[50px] md:w-[64px] cursor-pointer absolute float-right right-8 top-8" style={{ zIndex: 2 }}
                            onClick={() => {
                                openVideo(false);
                                setTimeView(0)
                                setIsPlay(false);
                            }}
                        >
                            <img src="/assets/images/footer/close.png" alt="close" className="" />
                        </div> : <div className="text-red w-full absolute float-right top-0 text-center" style={{ zIndex: 2 }}>
                            <div className="w-full relative bg-black">
                                <div className="w-full rounded-lg h-full py-2 flex gap-2 justify-center self-center">
                                    <img src='/assets/images/icons/clock.png' alt='clock' className="w-8 h-8 self-center" />
                                    <div className="self-center text-2xl font-bold font-cookie-run text-center">
                                        {TIME_REQUIRE - timeView}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </>
                }
            >
                <div
                    className="w-screen h-screen youtube"
                >
                    <VideoPlayer onPlay={onPlay} onPause={onPause} onEnd={onEnd} />
                </div>
            </Modal >

            <Modal
                key="emailForm"
                open={!!popup}
                style={{ width: "unset" }}
                className="bg-popup bg-cover mini-scale"
                closeIcon={
                    <div className="text-red w-[50px] md:w-[64px] cursor-pointer absolute float-right -right-2 -top-2" style={{ zIndex: 2 }}
                        onClick={() => {
                            setPopup(0);
                            setTelegramStep(1)
                        }}
                    >
                        <img src="/assets/images/footer/close.png" alt="close" className="" />
                    </div>
                }
            >
                <div
                    className="max-w-[564px] h-[333px] w-screen"
                >
                    <div className="max-w-[564px] h-[333px] w-screen pb-8">
                        {POPUP_UI[popup - 1]}
                    </div>
                </div>
            </Modal >
            <FitToViewport
                width={1400}
                height={1100}
                minZoom={0.1}
                maxZoom={1}
                className="overflow-hidden mystic-viewport"
                style={{
                    zIndex: 2
                }}
            >
                <div className="text-4xl text-white font-cookie-run text-header-shadow text-center">
                    CHECK-IN
                </div>
                <div className="text-6xl pt-2 font-cookie-bold text-white text-header-shadow text-center">
                    MYSTIC PLANET
                </div>
                <div className="relative w-full" key={`mystic-${claimed_quest?.length ?? 0}`}>
                    <img src='/assets/images/box-mission/bg.png' className="w-[634px] h-[634px] absolute top-[180px] left-[388px] mx-auto opacity-40" alt="elipse" />
                    <BoxComponent onClick={() => { setPopup(1) }} id={1} top="10px" left="560px" text="Submit a Fanart" isOpen={claimed_quest?.includes(1)} />
                    <BoxComponent onClick={() => { openVideo(true) }} id={2} top="300px" left="230px" text="Watch a video" isOpen={claimed_quest?.includes(2)} />
                    <BoxComponent onClick={() => { setPopup(3) }} id={3} top="610px" left="370px" text="Join Telegram channel" isOpen={claimed_quest?.includes(3)} />
                    <BoxComponent onClick={() => { setPopup(4) }} id={4} top="610px" left="756px" text="Follow Twitter" isOpen={claimed_quest?.includes(4)} />
                    <BoxComponent onClick={() => { setPopup(5) }} id={5} top="300px" left="896px" text="Refer 5 friends" isOpen={claimed_quest?.includes(5)} />

                    <div className="absolute w-max top-[360px] text-center" style={{ left: 'calc(50% - 125px)' }}>
                        <img src='/assets/images/box-mission/claim_btn.png' className={`cursor-pointer mx-auto w-[250px] ${claimed_quest?.length < 5 && 'disable-claim'}`} alt="elipse"
                            onClick={async () => {
                                if (claimed_quest?.length >= 5) {
                                    await claimStar(MongenRace.Mystic)
                                }
                            }} />
                    </div>
                </div>
            </ FitToViewport>
        </div >

    )
}

const BoxComponent = ({ id, top, left, text, isOpen, onClick }: { id: number, top: string, left: string, text: string, isOpen: boolean, onClick?: any }) => {
    return (
        <div key={`${id}-${isOpen}`} className={`absolute max-w-max ${isOpen ? '' : id % 2 === 0 ? 'giftbox-anim-1' : 'giftbox-anim-2'}`}
            onClick={onClick}
            style={{
                top,
                left
            }}>
            {isOpen ? <img src='/assets/images/box-mission/box-open.png' className="cursor-pointer w-[280px]" alt="elipse" />
                : <img src='/assets/images/box-mission/box-close.png' className="cursor-pointer w-[280px]" alt="elipse" />
            }
            <div className={`mission-text text-lg text-white font-cookie ${isOpen ? '-translate-y-[100%]' : '-translate-y-[175%]'} w-max mx-auto absolute left-[50%] -translate-x-[50%]`}>
                {text}
            </div>
        </div >
    )
}

import Header from "layout/header";
import { useState } from "react";
import { Modal } from "semantic-ui-react";

const BACKER_DATA = require('../../interfaces/partner.json');

export const Welcome = () => {
  return (
    <div className="w-full h-full min-w-screen md:min-h-screen justify-center bg-banner1 bg-contain">
      <Header />
      {/* <div className="w-full flex justify-center"> */}
      {/* <div className="w-full h-full relative min-w-screen min-h-screen pt-8 flex justify-center bg-contain transform-origin"> */}
      <div className="w-full h-full relative min-w-screen min-h-screen pt-8 relative justify-center bg-contain">
        {/* <FitToViewport
              width={1400}
              height={900}
              minZoom={0.1}
              maxZoom={8}
              className="overflow-hidden"
            > */}
        <div className="w-full text-4xl md:text-7xl pt-8 font-cookie-bold text-white text-header-shadow text-center">
          MONSTERRA 1st ANNIVERSARY
        </div>

        <div className="relative w-full mt-10 md:mt-0 flex md:block flex-wrap justify-center">
          <div className={`absolute top-[24vh] left-[33%] partner`}>
            <PartnerIndicator name="Crescent Shine Studio " icon="/assets/images/partner/creshent_shine.png" data={BACKER_DATA['creshentshine']} />
          </div>
          <div className={`absolute top-[8vh] left-[28%] partner`}>
            <PartnerIndicator name="hashed" icon="/assets/images/partner/hashed.jpg" data={BACKER_DATA['hashed']} />
          </div>
          <div className={`absolute top-[6vh] left-[48%] partner`}>
            <PartnerIndicator name="huobi ventures" icon="/assets/images/partner/huobi.png" data={BACKER_DATA['huobi']} />
          </div>
          <div className={`absolute top-[8vh] left-[8%] partner`}>
            <PartnerIndicator name="meta apes" icon="/assets/images/partner/meta_apes.png" data={BACKER_DATA['metaapes']} />
          </div>
          <div className={`absolute top-[12vh] right-[10%] partner`}>
            <PartnerIndicator name="aura network" icon="/assets/images/partner/aura.png" data={BACKER_DATA['aura']} />
          </div>
          <div className={`absolute top-[28vh] right-[20%] partner`}>
            <PartnerIndicator name="icetea labs" icon="/assets/images/partner/icetealabs.png" data={BACKER_DATA['icetealabs']} />
          </div>
          <div className={`absolute top-[45vh] right-[30%] partner`}>
            <PartnerIndicator name="salad ventures" icon="/assets/images/partner/salad.png" data={BACKER_DATA['salad']} />
          </div>
          <div className={`absolute top-[45vh] right-[60%] partner`}>
            <PartnerIndicator name="shima capital" icon="/assets/images/partner/shima.png" data={BACKER_DATA['shima']} />
          </div>
        </div>
        {/* </ FitToViewport> */}
      </div>
      <img src='/assets/images/welcome/mongen_decor.png' alt="decor" className="decor-mongen absolute top-[100px] right-0 w-[20%] translate-y-[80%] z-22" />
    </div>
  )
}


const PartnerIndicator = ({ icon, name, data }: { icon: string, name: string, data: { description: string, link: string, banner: string } }) => {
  const isReverse = ['aura network', 'icetea labs'].includes(name)
  const [openPopup, setPopup] = useState(false);

  return (
    <div className="tooltip-container">
      <div className="tooltip-trigger partner-shadow p-4" onClick={() => {
        setPopup(true)
      }}>
        <div className="partner-container flex relative w-max px-6 py-2 cursor-pointer" onClick={() => {
          window.open(data.link, "_blank")
        }}>
          <img src={icon} alt={name} className="object-contain h-[42px] mr-2 rounded-3xl metaimg self-center" />
          <div className="font-cookie-run text-uppercase font-bold text-lg text-white self-center px-3 metaname">
            {name}
          </div>
          <img src='/assets/images/banner/indicator.png' alt='indicator' className="indicator absolute bottom-0 left-[38px] translate-y-[102%] objec" />
        </div>
      </div>
      <div
        className={` ${isReverse ? "tooltip-content-reverse" : "tooltip-content"}  break-words bg-black bg-opacity-90 rounded-3xl overflow-hidden z-[10]`}>
        <div>
          <img src={`/assets/images/partner/${data.banner ?? 'cresentshine-banner.png'}`} alt='banner' className="w-full" />
        </div>
        <div className="text-uppercase font-cookie-run text-lg px-4 pt-4 font-bold">
          {name}
        </div>
        <div className="break-words font-cookie-run text-sm px-4 mt-4 pb-6">
          {data.description}
        </div>
      </div>
      <div className="block md:hidden">
        <Modal open={openPopup}
          closeIcon={
            <div className="cursor-pointer absolute float-right right-2 top-4">
              <img src='/assets/images/close-icon.png' alt="close" style={{ filter: 'invert(1' }} />
            </div>
          }
          onClose={() => {
            setPopup(false);
          }}
        >
          <div className="bg-black">
            <img src={`/assets/images/partner/${data.banner ?? 'cresentshine-banner.png'}`} alt='banner' className="w-full" />
          </div>
          <div className="text-uppercase font-cookie-run text-lg px-4 pt-4 font-bold">
            {name}
          </div>
          <div className="break-words font-cookie-run text-sm px-4 mt-4 pb-6">
            {data.description}
          </div>
        </Modal>
      </div>
    </div>
  )
}

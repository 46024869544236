import { SingleSignon } from "components/singlesignon/singlesignon";
import { SSO_META } from "config";
import { t } from "i18next";
import { CustomerInterface } from "interfaces";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "reducer/store";
import { clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { Button, Dropdown, Menu, Popup } from "semantic-ui-react";
import { ui, utils } from "services";

export default function Header() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const nav = useNavigate();
  const [clickedLogout, setClickedLogout] = useState(false);

  async function onLogoutClick() {
    setClickedLogout(true);
    await ui.confirm(t("Are you sure want to logout?"), () =>
      setClickedLogout(false)
    );
    dispatch(clearUserInfo());
    dispatch(clearAuthInfos());
    sessionStorage.clear();
    localStorage.clear();
    nav("/");
  }

  return (
    <div
      className="w-full main-header bg-[#1b003c] relative"
    >
      <img src="/assets/images/logo.png" alt="logo" className="absolute top-[16px] left-[12%] w-[10%] max-w-[150px] min-w-[120px]" />
      <Menu inverted className="main-container" borderless style={{
        background: '#1b003c'
      }}>
        <Menu.Menu inverted position="right">
          {user?.id > 0 && (
            <Menu.Item className="hoverless header-item">
              <Button
                color="blue"
                style={{
                  background: '#c7c3c1',
                  width: '120px',
                  color: 'black',
                  borderRadius: '30px'
                }}
                className="w-[84px] bg-[#c7c3c1]"
                onClick={() => {
                  onLogoutClick();
                }}
              >
                <span className="font-cookie-run text-bold text-lg text-uppercase">
                  Logout
                </span>
              </Button>
            </Menu.Item>
          )}
          {!user?.id && (
            <>
              <Menu.Item className="hoverless" key="popup signin">
                <SingleSignon
                  type="signin"
                  device="laptop"
                  SSO_META={SSO_META()}
                />
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
    </div>
  );
}

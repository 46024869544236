// @ts-ignore
import { useEffect, useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { Button, Modal, Pagination, Table, TableRow } from "semantic-ui-react";
// @ts-ignore
import { HappyBirthdayResource } from "interfaces";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, ui } from "services";
import { Character } from "home";
import { useTranslation } from "react-i18next";

export const RewardSection = ({ claimed_star, updateQuest, characterList, star_spent }: any) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [popupDeal, setPopupDeal] = useState(false);
  const [requestCharacters, setRequestCharacters] = useState([]);
  const [offerCharacters, setOfferCharacters] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();

  const claimReward = async (star: number) => {
    try {
      let { rewards, customerQuest } = await api.postEventDeal('/birthday/redeem-star-reward', { star });
      updateQuest && updateQuest(customerQuest);
      ui.openRewardPopup(rewards);
    } catch (err: any) {
      ui.alertFailed(t(err?.message), "")
    }
  }

  const isChecked = (list: { character: string, idx: number }[], character: string, idx: number) => {
    let info = list.find((item) => item.character === character && item.idx === idx)
    return !!info;
  }

  const offer = async () => {
    try {
      let { customerQuest } = await api.postEventDeal('/birthday/create-offer', {
        request_characters: requestCharacters.map((item) => HappyBirthdayResource[item.character]),
        offer_characters: offerCharacters.map((item) => HappyBirthdayResource[item.character])
      });
      updateQuest && updateQuest(customerQuest);
      setTrigger(!trigger)
      ui.alertSuccess('Create offer success', "");
      setPopupDeal(false)
    } catch (err: any) {
      ui.alertFailed(t(err?.message), "")

    }
  }

  const trade = async (id: number) => {
    try {
      let { customerQuest } = await api.postEventDeal('/birthday/trade', {
        offer_id: id
      });
      updateQuest && updateQuest(customerQuest);
      setTrigger(!trigger)
      ui.alertSuccess('Successfully accept order', "");
    } catch (err: any) {
      ui.alertFailed(t(err?.message), "")
    }
  }

  const cancel = async (id: number) => {
    try {
      let { customerQuest } = await api.postEventDeal('/birthday/cancel-offer', {
        offer_id: id
      });
      updateQuest && updateQuest(customerQuest);
      setTrigger(!trigger)
      ui.alertSuccess('Cancel offer success', "");
    } catch (err: any) {
      ui.alertFailed(t(err?.message), "")
    }
  }

  useEffect(() => {
    const load = async () => {
      let { transactionInfos, total } = await api.postEventDeal('/birthday/get-list-offer', {
        offset: 5 * (page - 1),
        limit: 5
      })
      setTotal(total)
      setData(transactionInfos)
    }

    if (user?.id)
      load();
  }, [user?.id, page, trigger])

  useEffect(() => {
    setRequestCharacters([])
    setOfferCharacters([])
  }, [popupDeal])

  const currentStar = (claimed_star?.length ?? 0) - (star_spent ?? 0);

  // Page 8
  return (
    <div className="bgw-full h-full min-w-screen min-h-screen relative bg-banner9 bg-contain w-full h-full flex justify-center pt-8 transform-origin ">
      <Modal
        key="emailForm"
        open={!!popupDeal}
        style={{ width: "unset" }}
        className="bg-popup"
      >
        <div
          className="w-full"
        >
          <div className="w-[70vw] h-[41.25vw] bg-contain bg-no-repeat pb-8">
            <div className="text-red cursor-pointer absolute float-right -right-2 -top-2"
              onClick={() => {
                setPopupDeal(false);
              }}
            >
              <img src="/assets/images/footer/close.png" alt="close" className="w-full max-w-[7vw]" />
            </div>
            <div className="pt-[3vw]">
              <div className="text-white font-cookie-run font-bold text-[2vw] px-[12.5%]">Select characters you want to receive</div>
              <div className="flex gap-[0.5vw] self-center justify-center mt-[1vw]">
                {"HAPPY BIRTHDAY".split('').map((character, idx) => {
                  if (character === ' ') {
                    return <div className="w-[2vw]"></div>
                  }
                  return (
                    // @ts-ignore
                    <Character text={character} width="3.5vw" textSize="text-[2.2vw]" className="cursor-pointer"
                      disable={!isChecked(requestCharacters, character, idx)}
                      check={isChecked(requestCharacters, character, idx)}
                      onClick={() => {
                        let request = _.cloneDeep(requestCharacters);
                        let isExisted = request.find((i) => i.character === character && i.idx === idx);

                        if (isExisted) {
                          request = request.filter((i) => !(i.character === character && i.idx === idx));
                        } else {
                          if (request.length >= 3) {
                            return;
                          }
                          request.push({
                            character,
                            idx,
                          })
                        }
                        setRequestCharacters(request)
                      }} />
                  )
                })}
              </div>
              <div className="px-[12.5%] mt-[3%]">
                <div className="text-white font-cookie-run font-bold text-[2vw]">Select characters you want to exchange</div>
                <div className='bg-[#FFFFFF] bg-opacity-20 w-full h-[18vw] mt-[1vw] rounded-lg flex gap-[0.5vw] flex-wrap p-[1vw] overflow-y-scroll'>
                  {Object.keys(characterList).map((key: string) => {
                    let character = HappyBirthdayResource[Number(key)];
                    let amount = characterList[key];
                    return (
                      <>
                        {Array.from({ length: amount }).map((i, idx) => {
                          return (
                            <Character text={character} width="3.5vw" textSize="text-[2.2vw]" className="cursor-pointer"
                              disable={!isChecked(offerCharacters, character, idx)}
                              check={isChecked(offerCharacters, character, idx)}
                              onClick={() => {
                                let offer = _.cloneDeep(offerCharacters);
                                let isExisted = offer.find((i) => i.character === character && i.idx === idx);

                                if (isExisted) {
                                  offer = offer.filter((i) => !(i.character === character && i.idx === idx));
                                } else {
                                  if (offer.length >= 3) {
                                    return;
                                  }
                                  offer.push({
                                    character,
                                    idx,
                                  });
                                }
                                setOfferCharacters(offer);
                              }} />
                          )
                        })}
                      </>
                    )
                  })}
                </div>
                <div className="relative cursor-pointer mt-[1.5vw]" onClick={async () => {
                  if (offerCharacters?.length && requestCharacters?.length)
                    ui.confirmActionBirthday("Confirmation", 'Are your sure want to create offer ?',
                      'default', async () => {
                        await offer();
                      })
                }}>
                  <img src='/assets/images/chaos-planet/exchange_btn.png' alt='btn' className={`${!(offerCharacters?.length && requestCharacters?.length) && 'grayscale-[100]'}  w-[11vw] mx-auto`} />
                  <div className={`absolute text-[#003380] top-[50%] -translate-y-[50%] text-[1.5vw] font-cookie-run font-bold text-center w-full`} >
                    Create
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center items-center mx-auto bottom-10 absolute justify-center w-full">
            </div>
          </div>
        </div>
      </Modal >

      <FitToViewport
        width={1400}
        height={1100}
        minZoom={0.1}
        maxZoom={2}
        className="overflow-hidden"
      >
        <div className="text-4xl text-white font-cookie-run text-header-shadow text-center mt-4">
          GET YOUR
        </div>
        <div className="text-6xl pt-2 font-cookie-bold text-white text-header-shadow text-center">
          REWARDS
        </div>
        <div className="relative w-full mt-16 flex justify-between gap-4">
          <div className="relative w-full">
            <img src='/assets/images/footer/bg-reward-reverse.png' alt='bg' className="absolute" />
            <div className="text-6xl top-[30px] text-center w-full pt-2 font-cookie-bold text-white text-header-shadow text-center absolute">
              TRADE
            </div>
            <div className="absolute top-[120px] left-[50%] -translate-x-[50%]">
              <Table ui="true" single="true" line="true" unstackable selectable inverted sortable padded className={`no-border-header overflow-x-auto`}>
                <Table.Header className="sticky top-0"
                  style={{ background: "rgb(0,0,0, 0.4)", zIndex: "20", borderRadius: '40px' }}
                >
                  <Table.Row className="w-full">
                    {["Player", "Request", "Offer"].map((header: any, i: number) => {
                      return <Table.HeaderCell key={`head-${i}`} className="text-2xl text-center self-center justify-center">
                        <div className="w-full mx-auto text-center">
                          {header}
                        </div>
                      </Table.HeaderCell>
                    })}
                    <Table.HeaderCell colSpan={2} key={`head-action`} className="text-2xl  text-center self-center" >
                      <div className="w-[90px]">
                      </div>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body className="relative">
                  {data.map((item: any, i: number) => {
                    const inventoryAmount = _.cloneDeep(characterList);

                    return (
                      <Table.Row key={`row-${i}`}>
                        <Table.Cell key={`header-trade-${i}`}>
                          <div className="self-center mx-auto w-[120px] text-center justify-center text-xl">
                            User #{item?.customer_id}
                          </div>
                        </Table.Cell>
                        <Table.Cell key={`header-trade-${i}`}>
                          <div className="self-center mx-auto text-center justify-center flex gap-2">
                            {item?.request?.map((i: HappyBirthdayResource) => {
                              return (
                                <Character text={HappyBirthdayResource[i]} width="32px" />
                              )
                            })}
                          </div>
                        </Table.Cell>
                        <Table.Cell key={`header-trade-${i}`}>
                          <div className="self-center mx-auto text-center justify-center flex gap-2">
                            {item?.offer?.map((i: HappyBirthdayResource) => {
                              return (
                                <Character text={HappyBirthdayResource[i]} width="32px" />
                              )
                            })}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="">
                          <div className="w-full h-full mx-auto text-center justify-center">
                            {item.customer_id !== user?.id && <Button
                              color="blue"
                              style={{
                                background: '#62FFFF',
                                width: '120px',
                                color: 'black',
                                borderRadius: '30px'
                              }}
                              onClick={() => {
                                ui.confirmActionBirthday("Confirmation", <div>
                                  <div>Are your sure want to trade with this offer ?</div>
                                  <div className="self-center mx-auto text-center justify-center flex gap-2 mt-4">
                                    {item.request.map((i: HappyBirthdayResource) => {
                                      let amount = inventoryAmount[i];
                                      inventoryAmount[i] -= 1;

                                      return (
                                        <Character text={HappyBirthdayResource[i]} width="32px" disable={amount <= 0} />
                                      )
                                    })}</div>
                                </div>,
                                  'default', async () => {
                                    await trade(item.id);
                                  })
                              }}
                            >
                              <span className="font-cookie-run font-bold text-md text-uppercase">
                                TRADE
                              </span>
                            </Button>}
                            {item.customer_id === user?.id && <Button
                              color="blue"
                              style={{
                                background: '#ffe402',
                                width: '120px',
                                color: 'black',
                                borderRadius: '30px'
                              }}
                              onClick={() => {
                                ui.confirmActionBirthday("Confirmation", 'Are your sure want to cancel this offer ?',
                                  'default', async () => {
                                    await cancel(item.id);
                                  })
                              }}
                            >
                              <span className="font-cookie-run font-bold text-md text-uppercase">
                                CANCEL
                              </span>
                            </Button>}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                  <TableRow>
                  </TableRow>
                </Table.Body>
              </Table>
            </div>

            <div className="absolute top-[600px] left-[50%] -translate-x-[50%]">
              <Pagination
                defaultActivePage={page}
                totalPages={Math.ceil(total / 5)}
                onPageChange={(event: any, pageInfo) => {
                  setPage(Number(pageInfo.activePage));
                }}
                firstItem={null}
                lastItem={null}
                className='bg-transparent custom-pagination mx-auto'
              />
              <div className="relative cursor-pointer mt-10" onClick={() => {
                setPopupDeal(true)
              }}>
                <img src='/assets/images/chaos-planet/exchange_btn.png' alt='btn' className={`w-[180px] mx-auto`} />
                <div className={`absolute text-[#003380] top-[50%] -translate-y-[50%] text-[24px] font-cookie-run font-bold text-center w-full ${!user?.id && 'grayscale-[100]'}`}>
                  Create deal
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full">
            <img src='/assets/images/footer/bg-reward.png' alt='bg' className="absolute" />
            <div className="text-6xl top-[30px] text-center w-full pt-2 font-cookie-bold text-white text-header-shadow text-center absolute">
              REWARDS
            </div>
            <div className="absolute top-[120px] left-[50%] -translate-x-[50%] w-full px-10">
              {Array.from({ length: 5 }).map((id, idx) => {
                return (
                  <div className="flex justify-between mt-6 bg-black bg-opacity-60 text-white font-cookie-run px-10 h-[84px] rounded-3xl">
                    <div className="self-center text-center text-2xl">
                      Redeem {idx + 1} {idx + 1 > 1 ? 'stars' : 'star'}
                    </div>
                    <div className="self-center flex gap-[20px]">
                      <div className="flex gap-6">
                        <div className="border border-[#62FFFF] border-[2px] w-[60px] h-[60px] flex rounded-xl">
                          <img src={`/img/reward/box/${idx + 4}.webp`} alt='png' className="self-center" />
                        </div>
                      </div>
                      <div className="self-center">
                        <Button
                          color="blue"
                          style={{
                            background: '#62FFFF',
                            width: '120px',
                            color: 'black',
                            borderRadius: '30px'
                          }}
                          disabled={currentStar < idx + 1}
                          className="cursor-pointer"
                          onClick={async () => {
                            if (currentStar < idx + 1) return;
                            ui.confirmActionBirthday('Confirmation', 'This action will consume your stars and cannot be undone. Are you sure you want to redeem your stars to claim these rewards?', '',
                              async () => {
                                await claimReward(idx + 1);
                              }
                            )
                          }}
                        >
                          <span className="font-cookie-run font-bold text-md text-uppercase">
                            CLAIM
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="top-[680px] text-center w-full pt-2  absolute flex gap-2 justify-center">
              <div className="text-3xl font-cookie-bold text-white text-header-shadow text-center self-center">
                CURRENT STARS: {currentStar}
              </div>
              <div className="self-center">
                <img src='/img/star-fill.png' alt='star' className="w-8 h-8" />
              </div>
            </div>
          </div>
        </div>
      </ FitToViewport>
      {/* <img src='/assets/images/banner/divider.png' alt='divider' className="w-full h-[80px] z-99 absolute top-0 -translate-y-[40%]" /> */}
    </div >
  )
}

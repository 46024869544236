import { StarBackground } from "home"
import { FitToViewport } from "react-fit-to-viewport"

export const Planets = () => {
    function scrollToId(id: string) {
        const element = document.getElementById(id);

        if (element) {
            const offsetTop = element.offsetTop;
            console.log({ element, offsetTop })
            // Scroll to the element's position
            window.scrollTo({
                top: offsetTop,
                behavior: 'auto'
            });
        }
    }

    return (
        <div className="w-full h-screen relative flex justify-center bg-contain bg-banner2 transform-origin">
            <StarBackground />
            <FitToViewport
                width={1445}
                height={1000}
                minZoom={0.1}
                maxZoom={3}
                className="overflow-hidden"
                style={{
                    zIndex: 3
                }}
            >
                <div className="relative w-full">
                    <img src='/assets/images/planet/elipse.png' className="w-[740px] h-[740px] absolute top-[100px] left-[350px] mx-auto" alt="elipse" />
                    <img
                        onClick={() => {
                            scrollToId('mystic-planet')
                        }}
                        src='/assets/images/planet/mystic.png' className="planet cursor-pointer h-[380px] absolute top-[000px] left-[580px]" alt="elipse" />
                    <img
                        onClick={() => {
                            scrollToId('beast-planet')
                        }}
                        src='/assets/images/planet/beast.png' className="planet cursor-pointer h-[370px] absolute top-[210px] left-[150px]" alt="elipse" />
                    <img
                        onClick={() => {
                            scrollToId('chaos-planet')
                        }}
                        src='/assets/images/planet/chaos.png' className="planet cursor-pointer h-[374px] absolute top-[210px] right-[158px]" alt="elipse" />
                    <img
                        onClick={() => {
                            scrollToId('celest-planet')
                        }}
                        src='/assets/images/planet/celest.png' className="planet cursor-pointer h-[358px] absolute top-[574px] left-[320px]" alt="elipse" />
                    <img
                        onClick={() => {
                            scrollToId('tectos-planet')
                        }}
                        src='/assets/images/planet/tectos.png' className="planet cursor-pointer h-[380px] absolute top-[554px] right-[320px]" alt="elipse" />
                    <div className="absolute top-[350px] w-full z-[-1]">
                        <div className="text-xl font-cookie text-white text-center" key='text-mystic'>
                            Warm up with exciting social missions
                        </div>
                    </div>
                    <div className="absolute top-[545px] left-[148px] z-[-1]" key='text-beast'>
                        <div className="text-xl font-cookie text-white text-center">
                            Up to 50K $USDT Tournament <br />
                            with epic Clans battle
                        </div>
                    </div>
                    <div className="absolute top-[545px] right-[220px] z-[-1]" key='text-chaos'>
                        <div className="text-xl font-cookie text-white text-center">
                            Spin the Wheel for <br />fabulous prizes
                        </div>
                    </div>
                    <div className="absolute top-[900px] left-[386px] z-[-1]" key='text-celest'>
                        <div className="text-xl font-cookie text-white text-center">
                            Co-host tasks with <br />valuable partners
                        </div>
                    </div>
                    <div className="absolute top-[900px] right-[336px] z-[-1]" key='text-tectos'>
                        <div className="text-xl font-cookie text-white text-center">
                            Complete gaming missions<br /> for another star
                        </div>
                    </div>
                    <div className="absolute top-[434px] w-full z-[-1]">
                        <div className="text-4xl font-cookie text-white text-center">
                            EXPLORE
                        </div>
                        <div className="text-6xl mt-4 font-cookie-bold text-white text-header-shadow text-center">
                            MONS GALAXY
                        </div>
                    </div>
                </div>
            </ FitToViewport>
            {/* <div className="absolute left-0 bottom-[100px] w-full h-full"> */}
            <img src='/assets/images/planet/decor.png' alt='decor' className="w-[20vw] absolute left-0 bottom-0" />
            {/* </div> */}
        </div>
    )
}
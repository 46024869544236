// @ts-ignore
import { useMemo, useState } from "react";
import { FitToViewport } from "react-fit-to-viewport";
import { Button } from "semantic-ui-react";
// @ts-ignore
import { HappyBirthdayResource, MongenRace } from "interfaces";
import { useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, ui } from "services";
import { Character, StarBackground } from "home";

export const TectosPlanet = ({ list_quest, claimed_quest, claimStar, claimed_star, updateQuest }: any) => {
    const user = useSelector((state: RootState) => state.user);
    const [listQuest, setListQuest] = useState(list_quest)
    const [loading, setLoading] = useState(null)

    const claimTask = async ({ quest_id, group_id }: { quest_id: number, group_id: number }) => {
        setLoading(quest_id)
        try {
            let { customerQuest, rewards } = await api.postEventDeal('/birthday/claim-tectos-quest', {
                quest_id, group_id
            })
            updateQuest && updateQuest(customerQuest)
            ui.openRewardPopup(rewards);
        } catch (err) {
            console.log({ err })
        } finally {
            setLoading(null)
        }
    }


    useMemo(() => {
        const loadProgess = async () => {
            let event_ids = list_quest.map((quest: any) => quest.event_id);

            if (user?.id) {
                let { propertyInfos } = await api.post('/event/get-event-claim-info', {
                    event_ids,
                })

                let questData = list_quest.map((quest: any) => {
                    const quest_id = quest.event_id;
                    const progressInfo = propertyInfos?.find((i: any) => i.event_id === quest_id);
                    return {
                        ...quest,
                        value: progressInfo?.value ?? 0
                    }
                })
                setListQuest(questData)
            } else {
                let questData = list_quest.map((quest: any) => {
                    return {
                        ...quest,
                        value: 0
                    }
                })
                setListQuest(questData)
            }
        }

        loadProgess();
    }, [user?.id, list_quest])

    return (
        <div className="transform-origin w-full h-full relative min-w-screen min-h-screen pt-8 flex justify-center bg-banner6 bg-contain" id='tectos-planet'>
            <StarBackground />
            <FitToViewport
                width={1400}
                height={1100}
                minZoom={0.1}
                maxZoom={1}
                className="overflow-hidden"
            >
                <div className="text-4xl text-white font-cookie-run text-header-shadow text-center mt-4">
                    CHECK-IN
                </div>
                <div className="text-6xl pt-2 font-cookie-bold text-white text-header-shadow text-center">
                    TECTOS PLANET
                </div>
                <div className="relative w-full mt-8 px-10">
                    {listQuest?.map((questInfo: any) => {
                        const isClaimable = questInfo.value >= questInfo.require;
                        const isClaimmed = claimed_quest?.includes(questInfo.quest_id);

                        return (
                            <div key={questInfo.quest_id} className="flex justify-between mt-6 bg-black bg-opacity-60 text-white font-cookie-run px-10 h-[80px] rounded-3xl">
                                <div className="self-center text-center text-xl">
                                    {questInfo.title}
                                </div>
                                <div className="self-center flex gap-[50px]">
                                    <div className="flex gap-6">
                                        {questInfo.rewards.map((i: number) => {
                                            return (
                                                <div className=" flex rounded-xl">
                                                    <Character text={HappyBirthdayResource[i]} width="64px" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="self-center">
                                        <Button
                                            color="blue"
                                            style={{
                                                background: '#62FFFF',
                                                width: '180px',
                                                color: 'black',
                                                borderRadius: '30px'
                                            }}
                                            disabled={isClaimmed}
                                            loading={loading === questInfo.quest_id}
                                            disable={loading !== null}
                                            onClick={async () => {
                                                if (!isClaimable || isClaimmed) {
                                                    return;
                                                }
                                                await claimTask({
                                                    quest_id: questInfo.quest_id,
                                                    group_id: questInfo.group_id
                                                })
                                            }}
                                        >
                                            <span className="font-cookie-run font-bold text-lg text-uppercase">
                                                {isClaimmed ? 'Claimed' : isClaimable ? 'Claim' : `${questInfo.value + " / " + questInfo.require}`}
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {!claimed_star?.includes(MongenRace.Tectos) && <img src='/assets/images/beast-planet/claim_btn.png'
                    className={`${claimed_quest?.length < list_quest?.length && 'disable-claim'} cursor-pointer mx-auto mt-8`} alt="elipse"
                    onClick={async () => {
                        if (claimed_quest?.length >= list_quest?.length) {
                            await claimStar(MongenRace.Tectos)
                        }
                    }}
                />}
            </ FitToViewport>
            <img src='/assets/images/tectos-planet/mongen_decor.png' alt='divider' className="w-[22vw] absolute bottom-[0px] right-[0px]" />
            {/* <img src='/assets/images/banner/divider.png' alt='divider' className="w-full h-[80px] z-99 absolute top-0 -translate-y-[40%]" /> */}
        </div>
    )
}

// @ts-ignore
import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
// @ts-ignore
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import { MongenRace, RewardType } from "interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducer/store";
import { api, ui } from "services";
import { BeastPlanet } from "./planet/beast";
import { CelestPlanet } from "./planet/celest";
import { ChaosPlanet } from "./planet/chaos";
import { EndingSection } from "./planet/ending-section";
import { MysticPlanet } from "./planet/mystic";
import { Planets } from "./planet/planet";
import { RewardSection } from "./planet/reward-section";
import { TectosPlanet } from "./planet/tectos";
import { bulkSetUserInfo, clearUserInfo } from "reducer/user.slice";
import { clearAuthInfos } from "reducer/wallet.slice";
import { useTranslation } from "react-i18next";
import { Welcome } from "./planet/welcome";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

export default function HomePage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState(false);
  const [config, setConfig] = useState(null);
  const [customerQuestInfo, setCustomerQuestInfo] = useState(null);
  const [event, setEvent] = useState([]);
  const [openClaimBirthday, setOpenClaimBirthday] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function loadUserInfo() {
    let token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    let rs = await api.post("/event/get-customer-info", {});
    dispatch(bulkSetUserInfo({
      id: rs.A,
      name: rs.D,
      ...rs
    }));
  }

  function scrollToId(id: string) {
    const element = document.getElementById(id);

    if (element) {
      const offsetTop = element.offsetTop;
      console.log({ element, offsetTop })
      // Scroll to the element's position
      window.scrollTo({
        top: offsetTop,
        behavior: 'auto'
      });
    }
  }

  useEffect(() => {
    const blob = document?.querySelector(".blob");
    const blobFn = (e: any) => {
      const { clientX, clientY } = e;
      if (blob)
        blob.animate(
          {
            left: `${clientX}px`,
            top: `${clientY}px`,
          },
          { duration: 2000, fill: "forwards" }
        );
    }

    window?.addEventListener("pointermove", blobFn);
    return () => {
      window?.removeEventListener("pointermove", blobFn);
    }
  }, [document?.querySelector(".blob")])


  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        let customerQuestInfo = await api.postEventDeal('/birthday/get-customer-quest-info', {});
        setCustomerQuestInfo(customerQuestInfo);
      } catch (err: any) {
        if (err?.message === 'require_level_7') {
          sessionStorage.clear();
          localStorage.clear();
          dispatch(clearUserInfo());
          dispatch(clearAuthInfos());
          ui.alertFailed(t(err?.message), "");
        }
      } finally {
        setLoading(false);
      }
    }

    if (user?.id) {
      load();
      loadUserInfo();
    }
  }, [user?.id]);

  useEffect(() => {
    async function loadConfig() {
      try {
        setLoading(true);
        let config = await api.postEventDeal('/get-config', {});
        setConfig(config)
      } finally {
        setLoading(false);
      }
    }
    loadConfig();
  }, []);

  useMemo(() => {
    async function getEvent() {
      try {
        let event = await api.post('/event-group/get-all-event-groups', {
          "status": "new",
          "domain": "birthday.monsterra.io"
        })
        if (event[0]) {
          let birthdayEvent = event[0];
          let questInfos = birthdayEvent.eventInfos.map((eventInfo: any) => {
            let questConfig = config?.BIRTHDAY_QUEST?.find((i: any) => i.quest_id === eventInfo.quest_id)

            return {
              ...questConfig,
              event_id: eventInfo.id,
              title: eventInfo.name,
              group_id: event[0].id
            }
          })
          setEvent(questInfos);
        }

      } catch (err) {
        console.log({ err })
      }
    }

    if (config) {
      getEvent();
    }
  }, [config])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');

    if (code) {
      sessionStorage.setItem('discordToken', code)
      if (window.history.replaceState) {
        const newUrl = window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }
      setTimeout(() => {
        scrollToId('celest-planet')
      }, 500)
    }
  }, []);

  const claimStar = async (race: number) => {
    try {
      let { customerQuest } = await api.postEventDeal('/birthday/claim-star-task', {
        race
      })
      setCustomerQuestInfo(customerQuest)
      ui.openRewardPopup([{ reward_quantity: 1, reward_type: RewardType.BirthdayStar }])
    } catch (err) {
      console.log({ err })
    }
  }

  useEffect(() => {
    let claimedStars = customerQuestInfo?.claimed_star ?? [];
    if (customerQuestInfo && !claimedStars.includes(MongenRace.All) && user?.id) {
      setOpenClaimBirthday(true)
    }
  }, [!!customerQuestInfo?.claimed_star, user?.id])

  return (
    <>
      <Modal
        key="emailForm"
        open={showPopup}
        style={{ width: "unset", background: 'transparent' }}
        closeOnDimmerClick={true}
      >
        <div
          className="w-full h-full"
        >
          <div className="text-red cursor-pointer absolute float-right top-[2%] right-[2%]"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <img src="/assets/images/footer/close.png" alt="close" className="w-full max-w-[10vw]" />
          </div>
          <img src='/assets/images/welcome/banner.png' className="w-full h-full object-contain" alt='bg' />
        </div>
      </Modal>

      <Modal
        key="emailForm"
        open={openClaimBirthday}
        style={{ width: "unset", background: 'transparent' }}
        closeOnDimmerClick={true}
        className="bg-popup bg-cover mini-scale"
      >
        <div
          className="max-w-[846px] h-[499.5px] w-screen"
        >
          <div className="text-red w-[50px] md:w-[64px] cursor-pointer absolute float-right -right-2 -top-2"
            onClick={() => {
              setOpenClaimBirthday(false);
            }}
          >
            <img src="/assets/images/footer/close.png" alt="close" className="w-full max-w-[10vw]" />
          </div>
          <div className="w-11/12 mx-auto flex h-full self-center">
            <img src='/assets/images/celest-planet/gift.png' className="hidden md:block absolute top-0 left-0 w-[200px]" alt='gift' />
            <img src='/assets/images/beast-planet/mongen-decor.png' className="hidden md:block absolute bottom-0 left-0 w-[200px]" alt='gift' />
            <img src='/assets/images/welcome/mongen_decor.png' className="hidden md:block absolute bottom-0 right-0 w-[200px]" alt='gift' />
            <div className="text-center text-white self-center justify-center font-cookie-bold text-4xl w-full -translate-y-[20px]">
              Let's celebrate Monsterra's 1st birthday. <br />We have a small gift for you!
            </div>
          </div>
          <div className="w-full mx-auto self-center flex justify-center absolute bottom-[20px]">
            <img src='/assets/images/beast-planet/claim_btn.png' className={`cursor-pointer mx-auto w-[200px]`} alt="elipse"
              onClick={async () => {
                await claimStar(MongenRace.All)
                setOpenClaimBirthday(false);
              }} />
          </div>
        </div>
      </Modal>

      <div className="fixed  top-[15vh] left-[90%] w-[10%] z-20">
        <img src='/assets/images/welcome/gift_decor.png' alt="decor" className="absolute cursor-pointer sticky-box fixed" onClick={() => {
          setShowPopup(true)
        }} />
      </div>

      <div className="w-full bg-main h-full">
        <BrowserView>
          <div className="blob"></div>
          <Fullpage>
            <FullPageSections>
              <FullpageSection className='fullpage-section-1'>
                <StarBackground />
                <Welcome />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <Planets />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <StarBackground />
                <MysticPlanet claimed_quest={customerQuestInfo?.mystic_quest_claimed}
                  updateQuest={setCustomerQuestInfo}
                  claimStar={claimStar} setPopup={setCustomerQuestInfo} />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <StarBackground />
                <BeastPlanet
                  claimStar={claimStar}
                  claimed_star={customerQuestInfo?.claimed_star ?? []}
                />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <StarBackground />
                <CelestPlanet
                  config={config}
                  claimed_quest={customerQuestInfo?.celest_quest_claimed ?? []}
                  updateQuest={setCustomerQuestInfo}
                  claimStar={claimStar}
                  claimed_star={customerQuestInfo?.claimed_star ?? []}
                />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <TectosPlanet
                  list_quest={event}
                  claimed_quest={customerQuestInfo?.tectos_quest_claimed ?? []}
                  claimStar={claimStar}
                  claimed_star={customerQuestInfo?.claimed_star ?? []}
                  updateQuest={setCustomerQuestInfo}
                />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <StarBackground />
                <ChaosPlanet
                  reward_list={customerQuestInfo?.chaos_reward_list ?? []}
                  claimed_quest={customerQuestInfo?.chaos_quest_claimed ?? []}
                  updateQuest={setCustomerQuestInfo}
                  ticketQuantity={customerQuestInfo?.spin_turn ?? 0}
                  characterList={customerQuestInfo?.characters ?? {}}
                />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <StarBackground />
                <RewardSection
                  claimed_star={customerQuestInfo?.claimed_star ?? []}
                  reward_claimed={customerQuestInfo?.claimed_star_redeem_rewards ?? []}
                  updateQuest={setCustomerQuestInfo}
                  characterList={customerQuestInfo?.characters ?? {}}
                  star_spent={customerQuestInfo?.star_spent}
                />
              </FullpageSection>
              <FullpageSection className='fullpage-section'>
                <StarBackground />
                <EndingSection />
              </FullpageSection>
            </FullPageSections>
          </Fullpage>
        </BrowserView>

        <MobileView>
          <section className='fullpage-section-1'>
            <StarBackground />
            <Welcome />
          </section>
          <section className='fullpage-section'>
            <Planets />
          </section>
          <section className='fullpage-section'>
            <StarBackground />
            <MysticPlanet claimed_quest={customerQuestInfo?.mystic_quest_claimed}
              updateQuest={setCustomerQuestInfo}
              claimStar={claimStar} setPopup={setCustomerQuestInfo} />
          </section>
          <section className='fullpage-section'>
            <StarBackground />
            <BeastPlanet
              claimStar={claimStar}
              claimed_star={customerQuestInfo?.claimed_star ?? []}
            />
          </section>
          <section className='fullpage-section'>
            <StarBackground />
            <CelestPlanet
              config={config}
              claimed_quest={customerQuestInfo?.celest_quest_claimed ?? []}
              updateQuest={setCustomerQuestInfo}
              claimStar={claimStar}
              claimed_star={customerQuestInfo?.claimed_star ?? []}
            />
          </section>
          <section className='fullpage-section'>
            <TectosPlanet
              list_quest={event}
              claimed_quest={customerQuestInfo?.tectos_quest_claimed ?? []}
              claimStar={claimStar}
              claimed_star={customerQuestInfo?.claimed_star ?? []}
              updateQuest={setCustomerQuestInfo}
            />
          </section>
          <section className='fullpage-section'>
            <StarBackground />
            <ChaosPlanet
              reward_list={customerQuestInfo?.chaos_reward_list ?? []}
              claimed_quest={customerQuestInfo?.chaos_quest_claimed ?? []}
              updateQuest={setCustomerQuestInfo}
              ticketQuantity={customerQuestInfo?.spin_turn ?? 0}
              characterList={customerQuestInfo?.characters ?? {}}
            />
          </section>
          <section className='fullpage-section'>
            <StarBackground />
            <RewardSection
              claimed_star={customerQuestInfo?.claimed_star ?? []}
              reward_claimed={customerQuestInfo?.claimed_star_redeem_rewards ?? []}
              updateQuest={setCustomerQuestInfo}
              characterList={customerQuestInfo?.characters ?? {}}
              star_spent={customerQuestInfo?.star_spent}
            />
          </section>
          <section className='fullpage-section'>
            <StarBackground />
            <EndingSection />
          </section>
        </MobileView>
      </div>
    </>
  );
}

export const Character = ({
  text, width, className = "", disable = false, textSize = "text-4xl", onClick, check
}: {
  text: string, width: string, className?: string, disable?: boolean, textSize?: string, onClick?: any, check?: boolean
}) => {
  return (
    <div className={className} style={{
      width,
      height: width,
    }}
      onClick={onClick}
    >
      <div className={`relative w-full h-full ${disable ? 'bg-[#FFFFFF] bg-opacity-20' : 'bg-[#62FFFF]'} ${textSize} font-bold text-black font-cookie-bold flex self-center justify-center align-center`} style={{
        borderRadius: '20%'
      }}>
        {check && <div>
          <img src='/assets/images/celest-planet/check.png' alt='check' className="absolute top-[50%] -translate-y-[50%] w-2/3 left-[50%] -translate-x-[50%] z-20" />
        </div>}
        <div className="self-center font-extrabold text-black font-cookie-bold relative">
          {text}
        </div>
      </div>
    </div>
  )
}

export const Tutorial = () => {
  return (
    <>
      <div className="fixed bottom-0 left-4 hd:left-10 z-[99] hidden md:block transform w-[200px] hd:w-[300px]">
        <div className="relative">
          <img
            className={`cursor-pointer transform origin-bottom-left scale-100 duration-300`}
            alt='mongen'
            src="/assets/images/footer/tutorial-collapse.png"
            onClick={() => {
              window.open('https://play.monsterra.io', "_blank")
            }}
          />
          <div
            className="absolute bottom-20 hd:bottom-44 w-[560px] h-[248px] bg-contain bg-no-repeat px-6 py-4 transform scale-50 bg-tutorial"
          >
            <div className="relative">
              <div className="text-center">
                <div className="flex gap-1 justify-center self-center">
                  <div className="translate-y-[30%] font-cookie-bold font-bold text-black text-3xl">
                    Join the excitement of The Happy #Monsterra1 Tournament and stand a chance to win a mind-blowing $50,000 with your clans
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const StarBackground = () => {
  return (
    <div className="starcontainer">
      <div id="stars"></div>
      {/* <div id="stars2"></div> */}
      <div id="stars3"></div>
    </div>
  )
}

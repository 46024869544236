import React, { useState } from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ onPlay, onPause, onEnd }: any) => {
    return (
        <YouTube
            videoId="4zuH_ePeg1Y"
            onPlay={onPlay}
            onPause={onPause}
            onEnd={onEnd}
        />
    );
};

export default VideoPlayer;